import PropTypes from 'prop-types';
import React from 'react';

const Section = ({ id, title, description, website, children, image, subSection }) => {
  let headerTitle = title;
  if (website) {
    headerTitle = (
      <a href={website} target="_blank">
        {headerTitle}
      </a>
    );
  }
  headerTitle = subSection ? <h3>{headerTitle}</h3> : <h2>{headerTitle}</h2>;

  return (
    <section id={id}>
      <header>
        {headerTitle}
        {description && <div>{description}</div>}
      </header>
      <div className="content">
        {children}
        {image && (
          <span className="image main">
            <img src={image} alt={title} />
          </span>
        )}
      </div>
    </section>
  );
};

Section.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  website: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  image: PropTypes.string,
  subSection: PropTypes.bool,
};

export default Section;
