import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-scroll';

const GoTo = ({ section, children, className }) => (
  <Link to={section} smooth={true} offset={-36} className={`goto ${className || ''}`}>
    {children}
  </Link>
);

GoTo.propTypes = {
  section: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default GoTo;
