import PropTypes from 'prop-types';
import React from 'react';

import useSiteMetadata from '../hooks/use-site-metadata';

const Footer = (props) => {
  const { copyright } = useSiteMetadata();
  return (
    <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
      <p className="copyright">&copy; {copyright}</p>
    </footer>
  );
};

Footer.propTypes = {
  timeout: PropTypes.bool,
};

export default Footer;
