import React from 'react';

import full01 from '../assets/img/gallery/fulls/01.jpg';
import full02 from '../assets/img/gallery/fulls/02.jpg';
import full03 from '../assets/img/gallery/fulls/03.jpg';
import full04 from '../assets/img/gallery/fulls/04.jpg';
import full05 from '../assets/img/gallery/fulls/05.jpg';
import full06 from '../assets/img/gallery/fulls/06.jpg';
import full07 from '../assets/img/gallery/fulls/07.jpg';
import full08 from '../assets/img/gallery/fulls/08.jpg';
import full09 from '../assets/img/gallery/fulls/09.jpg';
import full10 from '../assets/img/gallery/fulls/10.jpg';
import thumb01 from '../assets/img/gallery/thumbs/01.jpg';
import thumb02 from '../assets/img/gallery/thumbs/02.jpg';
import thumb03 from '../assets/img/gallery/thumbs/03.jpg';
import thumb04 from '../assets/img/gallery/thumbs/04.jpg';
import thumb05 from '../assets/img/gallery/thumbs/05.jpg';
import thumb06 from '../assets/img/gallery/thumbs/06.jpg';
import thumb07 from '../assets/img/gallery/thumbs/07.jpg';
import thumb08 from '../assets/img/gallery/thumbs/08.jpg';
import thumb09 from '../assets/img/gallery/thumbs/09.jpg';
import thumb10 from '../assets/img/gallery/thumbs/10.jpg';
import logo from '../assets/img/logo.png';
import pic01 from '../assets/img/pic01.jpg';
import Footer from '../components/Footer';
import GoTo from '../components/GoTo';
import Layout from '../components/layout';
import Section from '../components/Section';
import useSiteMetadata from '../hooks/use-site-metadata';

const images01 = [
  {
    id: '1',
    source: full01,
    thumbnail: thumb01,
    caption: 'Photo 1',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
    landscape: true,
  },
  {
    id: '2',
    source: full02,
    thumbnail: thumb02,
    caption: 'Photo 2',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '3',
    source: full03,
    thumbnail: thumb03,
    caption: 'Photo 3',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '4',
    source: full04,
    thumbnail: thumb04,
    caption: 'Photo 4',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
    landscape: true,
  },
];

const images02 = [
  {
    id: '5',
    source: full05,
    thumbnail: thumb05,
    caption: 'Photo 5',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '6',
    source: full06,
    thumbnail: thumb06,
    caption: 'Photo 6',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '7',
    source: full07,
    thumbnail: thumb07,
    caption: 'Photo 7',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
    landscape: true,
  },
];

const images03 = [
  {
    id: '8',
    source: full08,
    thumbnail: thumb08,
    caption: 'Photo 8',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '9',
    source: full09,
    thumbnail: thumb09,
    caption: 'Photo 9',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '10',
    source: full10,
    thumbnail: thumb10,
    caption: 'Photo 10',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
    landscape: true,
  },
];

const IndexPage = () => {
  const { subtitle } = useSiteMetadata();
  return (
    <Layout>
      <section className="intro">
        <header>
          <h1>
            <GoTo section="first">
              We are Talking Cherry<span className="secondary">.</span>
            </GoTo>
          </h1>
          <p>
            {subtitle.split(' - ').map((sub, i) => (
              <span key={i} style={{ display: 'block' }}>
                {sub}
              </span>
            ))}
          </p>
          <ul className="actions">
            <li>
              <GoTo section="first" className="arrow">
                <span className="label">Know more</span>
              </GoTo>
            </li>
          </ul>
        </header>
        <div className="content">
          <span className="image fill" data-position="center">
            <img src={pic01} alt="" />
          </span>
          <span className="logo">
            <GoTo section="first">
              <img src={logo} />
            </GoTo>
          </span>
        </div>
      </section>

      <Section id="first" title="What is Talking Cherry?">
        <p>
          <strong>Talking Cherry is a mobile and website development studio.</strong> Multi-platform from
          start to end. Using the latests and more battle-tested technologies. From the mobile world capital,
          Barcelona.
        </p>
        <p>
          Technically speaking: <strong>React Native and React in TypeScript.</strong>
        </p>
      </Section>

      <Section title="Services">
        <p>
          <strong>We embrace your project from start to end:</strong>
        </p>
        <ul className="feature-icons">
          <li className="icon solid fa-brain">Idea, planning, roadmap, preproduction</li>
          <li className="icon solid fa-business-time">Development management</li>
          <li className="icon solid fa-code">Software development</li>
          <li className="icon solid fa-fire-extinguisher">Consultancy and process analysis</li>
          <li className="icon solid fa-chalkboard-teacher">Courses and training</li>
          <li className="icon solid fa-chart-line">Performance analysis</li>
        </ul>
      </Section>

      {/* <Section title="Public portfolio">
        <p>
          <strong>Due to the Non-disclosure Agreements</strong> signed with the majority of our clients and
          partners, this is what we can show you:
        </p>

        <Section subSection={true} title="E-Aditivos" description="" website="https://e-aditivos.com">
          <Gallery
            images={images01.map(({ id, source, thumbnail, caption, description, landscape }) => ({
              source,
              thumbnail,
              caption,
              description,
              landscape,
            }))}
          />
        </Section>
      </Section> */}

      <Section title="Contact us">
        <div>
          <form method="POST" action="https://formspree.io/moqkdqpy">
            <div className="fields">
              <div className="field half">
                <input type="text" name="name" id="name" placeholder="Name" />
              </div>
              <div className="field half">
                <input type="email" name="email" id="email" placeholder="Email" />
              </div>
              <div className="field">
                <textarea name="message" id="message" placeholder="Message" rows="7"></textarea>
              </div>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send message" className="button large primary" />
              </li>
            </ul>
          </form>
        </div>
        <footer>
          <ul className="items">
            <li>
              <h3>Correo electrónico</h3>
              <a href="mailto:info@talkingcherry.com">info@talkingcherry.com</a>
            </li>
          </ul>
        </footer>
      </Section>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
